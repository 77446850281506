import { Home } from 'modules/home';

export default Home;

// export function getStaticProps() {
//   return {
//     redirect: {
//       destination: '/maintenance',
//     },
//   };
// }
